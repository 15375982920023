import "../Aparts/Aparts.css";
import React, { useState, useRef } from "react";
import Promo from "../Promo/Promo";
import Scheme from "../Scheme/Scheme";
import Price from "../Price/Price";
import Gallery from "../Gallery/Gallery";
import { galleryHouses } from "../../utils/gallery";

import Questions from "../Questions/Questions";
import Quiz from "../Quiz/Quiz";
import Feedback from "../Feedback/Feedback";
import manuscriptImage from "../../images/Object/manuscript.svg";
import Advantages from "../Advantages/Advantages";
import LoadableImage from "../LoadableImage/LoadableImage";
import CustomVideoPlayer from "../CustomVideoPlayer/CustomVideoPlayer";

import openIcon from "../../images/Main/union.svg";
import Slider from "react-slick";

import useWindowSize from "../../hooks/useWindowSize";

import arrow from "../../images/Main/arrow.svg";
import {
  advantages,
  designPlans,
  factors,
  sliderImages,
} from "../../utils/constants";

import { Accordion, Panel } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";

import video1 from "../../images/RealizedGallery/leontievsky/video.mp4";
import poster1 from "../../images/RealizedGallery/leontievsky/poster.png";

import video2 from "../../images/RealizedGallery/mirozdanie/video.mp4";
import poster2 from "../../images/RealizedGallery/mirozdanie/poster.png";

const Aparts = ({ handleOpenPopup, handleOpenPopupRequest }) => {
  const [activeDesignElement, setActiveDesignElement] = useState(0);
  const [currentSliderImages, setCurrentSliderImages] = useState(
    sliderImages.first
  );
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlideIndexes, setCurrentSlideIndexes] = useState([0, 0, 0, 0]);
  const [activeKey, setActiveKey] = useState(null);
  const [activeKeySecond, setActiveKeySecond] = useState(0);
  const [isSliderVisible, setIsSliderVisible] = useState(true);

  const size = useWindowSize();

  const sliderRef = useRef(null);
  const sliderRefSecond = useRef(null);
  const designContainerRef = useRef(null);

  const handleSelect = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const handleSelectSecond = (key) => {
    setActiveKeySecond(activeKeySecond === key ? null : key);
  };

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`design__slider-button design__slider-button--prev`}
        onClick={onClick}
      >
        <img src={arrow} className="popup-image__arrow" alt="стрелка влево" />
      </button>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`design__slider-button design__slider-button--next`}
        onClick={onClick}
      >
        <img src={arrow} className="popup-image__arrow" alt="стрелка вправо" />
      </button>
    );
  };

  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    focusOnSelect: true,
    centerPadding: "0",
    speed: 300,
    swipeToSlide: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => setCurrentSlideIndex(next),
  };

  let settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    focusOnSelect: true,
    centerPadding: "0",
    swipeToSlide: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => handleSlideChange(activeKeySecond, next),
  };

  const handleDesignElementClick = (index) => {
    if (index === activeDesignElement) return;
    setActiveDesignElement(index);
    setIsSliderVisible(false);

    setTimeout(() => {
      switch (index) {
        case 0:
          setCurrentSliderImages(sliderImages.first);
          break;
        case 1:
          setCurrentSliderImages(sliderImages.second);
          break;
        case 2:
          setCurrentSliderImages(sliderImages.third);
          break;
        case 3:
          setCurrentSliderImages(sliderImages.fourth);
          break;
        default:
          setCurrentSliderImages(sliderImages.first);
      }

      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
      setTimeout(() => {
        setIsSliderVisible(true);
      }, 300);
    }, 300);
  };

  const handleSlideChange = (index, slideIndex) => {
    setCurrentSlideIndexes((prev) => {
      const newIndexes = [...prev];
      newIndexes[index] = slideIndex;
      return newIndexes;
    });
  };

  return (
    <section className="aparts">
      <Promo
        title={"Дизайн загородных домов “под ключ”"}
        handleOpenPopup={handleOpenPopup}
      />
      <LoadableImage
        src={manuscriptImage}
        className="main__manuscript"
        alt="Рукопись «Ваше новое качество жизни»"
      />
      <div className="service__question">
        <h2 className="service__question-title">
          Стоимость разработки дизайн-проекта загородного дома
        </h2>
        <Accordion activeKey={activeKey} onSelect={handleSelect}>
          <Panel
            eventKey="0"
            className="questions__container"
            header={
              <div className="questions__title-container">
                <h3 className="question__question">
                  Дизайн-проект загородного дома <br className="prices__wrap" />{" "}
                  от 5 000 руб. за м²
                </h3>
                <button
                  type="button"
                  className={`button questions__button ${
                    activeKey === "0" ? "questions__button_active" : ""
                  }`}
                >
                  <img
                    src={openIcon}
                    className={`question__open-image ${
                      activeKey === "0" ? "questions__open-image_active" : ""
                    }`}
                    alt="Значок вверх"
                  />
                </button>
              </div>
            }
          >
            <div className="questions__answer questions__answer_active">
              <div className="service__answer">
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Анализ объекта</li>
                  <li className="policy__element servise__answer-text">
                    Дизайнер выезжает на объект либо по вашим чертежам проверяет
                    планировку и все коммуникации.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Обмерный план</li>
                  <li className="policy__element servise__answer-text">
                    Обмерный план включает в себя точные замеры стен, мест
                    расположения колонн, выступающих конструкций и их высот.
                    Также на обмерном плане отображено расположение окон,
                    дверных проемов, указаны места проходимых коммуникаций. Это
                    является исходной информацией, необходимой для разработки
                    планировочного решения.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Техническое задание</li>
                  <li className="policy__element servise__answer-text">
                    Дизайнер на встрече с вами выясняет всю необходимую для
                    выполнения проекта информацию, такую как: состав семьи,
                    увлечения, гости, необходимое к размещению оборудование.
                    Также на встрече вы определяете стилистическое направление
                    вашего интерьера.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Планировочное решение
                  </li>
                  <li className="policy__element servise__answer-text">
                    Дизайнер на основании Технического задания, художественного
                    вкуса, норм эргономики и учитывая собственный опыт
                    разрабатывает до 3-х вариантов планировок/Вы видите как на
                    планах развивается пространство, показаны различные
                    комбинации по зонированию и наполнению помещений. На виде
                    сверху отображены условно предметы мебели и декора,
                    становятся отчетливыми проходные зоны и функционал.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Бюджетирование</li>
                  <li className="policy__element servise__answer-text">
                    На этом этапе мы определяем общий бюджет вашего объекта,
                    чтобы при создании проекта дизайнер использовал отделочные
                    материалы, производителей мебели, освещения, сантехники,
                    которые подходят вам по бюджету.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Фотореалистичная 3d-визуализация
                  </li>
                  <li className="policy__element servise__answer-text">
                    Модель вашего будущего интерьера, сделанная на компьютере,
                    на которых вы наглядно видите, как будет выглядеть ваш
                    интерьер, после окончания ремонтных работ.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Комплектация проекта
                  </li>
                  <li className="policy__element servise__answer-text">
                    Таблица с отделочными материалами, мебелью и декором.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Рабочая документация для строителей
                  </li>
                  <li className="policy__element servise__answer-text">
                    План демонтажа перегородок и инженерных коммуникаций;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План возводимых перегородок с маркировкой дверных проёмов;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План зонирования помещений с указанием площадей;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План расстановки мебели и сантехнического оборудования;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План полов с указанием типа напольных покрытий и площадей;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План размещения подогрева пола с привязкой регулятора;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План размещения сантехнического оборудования с габаритными
                    размерами и привязками;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Планы потолков с указанием типа потолка, размеров и сечений;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План размещения осветительных приборов, с привязкой
                    освещения и расстановки мебели;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План привязки выключателей с указанием включения групп
                    светильников;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План электрики и электро-выводов с привязками;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Развёртка стен с раскладкой кафельной плитки с указанием
                    размеров (количество чертежей зависит от уровня сложности);
                  </li>
                  <li className="policy__element servise__answer-text">
                    Спецификация заполнения дверных проёмов;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Ведомость отделки помещений;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Схема отделки помещений;
                  </li>
                  <li className="policy__element servise__answer-text">
                    План размещения принудительной вентиляции;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Составление плана привязки внутренних и внешних блоков
                    систем кондиционирования;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Разработка нестандартных элементов интерьера;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Детальный подбор материалов и мебели с артикулами;
                  </li>
                  <li className="policy__element servise__answer-text">
                    Разработка индивидуальной мебели.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Accordion>
      </div>
      <Gallery gallery={galleryHouses} small={true} />
      <div className="object__container">
        <h2 className="object__title object__title_type_video">
          Видеообзоры выполненных объектов
        </h2>
        <div className="service__videos">
          <CustomVideoPlayer videoSrc={video1} poster={poster1} />
          <CustomVideoPlayer videoSrc={video2} poster={poster2} />
        </div>
        <a
          className="button gallery__link"
          href="https://vk.com/video/@penta_pro"
          target="_blank"
          rel="noreferrer"
        >
          Посмотреть другие видеообзоры
        </a>
      </div>
      <Scheme />
      <div className="design">
        <h2 className="design__title">Дизайн-проект</h2>
        <p className="design__text">
          Вы получите персональный проект интерьера со всей рабочей
          документацией
        </p>
        {size.width > 576 && (
          <div ref={designContainerRef} className="design__container">
            <div className="design__column">
              <div
                className={`design__element ${
                  activeDesignElement === 0 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(0)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 0 ? "design__number_active" : ""
                  }`}
                >
                  1
                </p>
                Планировочное решение
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 1 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(1)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 1 ? "design__number_active" : ""
                  }`}
                >
                  2
                </p>
                Технические чертежи
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 2 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(2)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 2 ? "design__number_active" : ""
                  }`}
                >
                  3
                </p>
                3D-визуализация
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 3 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(3)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 3 ? "design__number_active" : ""
                  }`}
                >
                  4
                </p>
                Спецификация
              </div>
            </div>
            <div
              className={`design__slider ${
                isSliderVisible ? "visible" : "hidden"
              }`}
            >
              <Slider className="plans" {...settings} ref={sliderRef}>
                {currentSliderImages.map((image) => (
                  <LoadableImage
                    src={image}
                    className="design__image"
                    alt="Схема"
                  />
                ))}
              </Slider>
              <p className="design__slider-index">
                {currentSlideIndex + 1}/{currentSliderImages.length}
              </p>
            </div>
          </div>
        )}
        {size.width < 576 && (
          <Accordion activeKey={activeKeySecond} onSelect={handleSelectSecond}>
            {designPlans.map((data, index) => (
              <Panel
                key={index}
                eventKey={index}
                header={
                  <div
                    className={`design__element ${
                      activeKeySecond === index ? "design__element_active" : ""
                    }`}
                  >
                    <p
                      className={`design__number ${
                        activeKeySecond === index ? "design__number_active" : ""
                      }`}
                    >
                      {data.number}
                    </p>
                    {data.title}
                    <button
                      type="button"
                      className={`button questions__button ${
                        activeKeySecond === index
                          ? "questions__button_active"
                          : ""
                      }`}
                    >
                      <img
                        src={openIcon}
                        className={`question__open-image ${
                          activeKeySecond === index
                            ? "questions__open-image_active"
                            : ""
                        }`}
                        alt="Значок вверх"
                      />
                    </button>
                  </div>
                }
              >
                <div className={`design__slider`}>
                  <Slider
                    className="plans"
                    {...settings2}
                    ref={sliderRefSecond}
                  >
                    {data.plans.map((image) => (
                      <LoadableImage
                        src={image}
                        className="design__image"
                        alt="Схема"
                      />
                    ))}
                  </Slider>
                  <p className="design__slider-index">
                    {currentSlideIndexes[activeKeySecond] + 1}/
                    {data.plans.length}
                  </p>
                </div>
              </Panel>
            ))}
          </Accordion>
        )}
      </div>
      <div className="factors">
        <h3 className="factors__title">
          9 факторов, от которых зависит цена на дизайн загородного дома
        </h3>
        <ul className="factors__list">
          {factors.map((element) => (
            <li className="factors__element" key={element.number}>
              <span className="factors__number">{element.number}</span>
              {element.factorAlt ? element.factorAlt : element.factor}
            </li>
          ))}
        </ul>
      </div>
      <Advantages advantages={advantages} />
      <Price />
      <Feedback />
      <Questions />
      <Quiz handleOpenPopupRequest={handleOpenPopupRequest} />
    </section>
  );
};

export default Aparts;
