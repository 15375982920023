import "./Burger.css";
import React from "react";
import { useState, useEffect } from "react";
import Navigation from "../Navigation/Navigation";
import Logo from "../Logo/Logo";

import telegramImage from "../../images/Footer/telegram.svg";
import whatsappImage from "../../images/Footer/whatsapp.svg";
import vkImage from "../../images/Footer/vk.svg";
import youtubeImage from "../../images/Footer/youtube.svg";
import instaImage from "../../images/Footer/insta.svg";

function Burger({ setBurger, onCalcClick, onFeedbackClick }) {
  const [isOpen, setOpen] = useState(false);

  const handleCloseButtonClick = () => {
    setOpen(false);
    setTimeout(setBurger, 300);
  };

  useEffect(() => {
    setTimeout(setOpen(true), 300);
  }, []);

  return (
    <div className="burger">
      <div
        className={`burger__container ${
          isOpen ? `burger__container_active` : `burger__container_deactive`
        }`}
      >
        <div className="burger__middle">
          <div className="burger__header">
            <div className="burger__logo">
              <Logo />
            </div>
            <button
              className="burger__close-button"
              onClick={handleCloseButtonClick}
            ></button>
          </div>
          <Navigation
            onCalcClick={onCalcClick}
            onFeedbackClick={onFeedbackClick}
            setOpen={handleCloseButtonClick}
            isOpen={isOpen}
          />
        </div>
        <div className="burger__footer">
          <div className="burger__nav">
            {/* prettier-ignore */}
            <pre className="footer__text">Большой проспект <br className="footer__wrap" />П.С., 65</pre>
            <a
              className="burger__link"
              href="tel:89335672715"
              target="_blank"
              rel="noreferrer"
            >
              +7 (933) 567-27-15
            </a>
            <a
              className="burger__link"
              href="mailto:soda@penta.pro"
              target="_blank"
              rel="noreferrer"
            >
              soda@penta.pro
            </a>
          </div>

          <ul className="burger__socials">
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="https://t.me/PentaSpb"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={telegramImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="https://wa.me/+79013166776"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={whatsappImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="https://vk.com/penta_pro"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={vkImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="https://www.youtube.com/channel/UCMZvRl2oYk_u9rNfnvCnkZA"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={youtubeImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="https://www.instagram.com/penta.pro?igsh=ZmJhdDloamkxa3Jx"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={instaImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
          </ul>
          {/* prettier-ignore */}
          <pre className="burger__policy">
            © 2024 Сода                                                                                                    Отправляя любую форму, вы соглашаетесь c{" "}
              <a href="/policy" className="footer__policy-link">
                Политикой конфиденциальности
              </a>
          </pre>
        </div>
      </div>
      <div
        className={`burger__overlay ${
          isOpen ? `burger__overlay_active` : `burger__overlay_deactive`
        }`}
        onClick={handleCloseButtonClick}
      ></div>
    </div>
  );
}

export default Burger;
