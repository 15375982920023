import "./Navigation.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

function Navigation({ setOpen }) {
  const [servicesOpen, setServicesOpen] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const size = useWindowSize();

  const handleLinkClick = () => {
    setServicesOpen(false);
    setGalleryOpen(false);
    setOpen();
  };

  const toggleGallery = () => {
    if (size.width <= 1024) {
      setGalleryOpen((prev) => !prev);
      if (servicesOpen) {
        setServicesOpen(false);
      }
    }
  };

  const toggleServices = () => {
    if (size.width <= 1024) {
      setServicesOpen((prev) => !prev);
      if (galleryOpen) {
        setGalleryOpen(false);
      }
    }
  };

  return (
    <nav className="navigation">
      <ul className="navigation__list">
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/about-us" className="navigation__link">
              О НАС
            </Link>
          </div>
        </li>

        {/* Галерея работ */}
        <li
          className="navigation__element"
          onClick={toggleGallery}
          onMouseEnter={() => size.width > 1024 && setGalleryOpen(true)}
          onMouseLeave={() => size.width > 1024 && setGalleryOpen(false)}
        >
          <div className="navigation__hover-area">
            <div className="navigation__el">
              <Link
                to={size.width > 1024 ? "/gallery" : "#"}
                className="navigation__link"
                onClick={size.width > 1024 ? handleLinkClick : null}
              >
                ГАЛЕРЕЯ РАБОТ
              </Link>
              {size.width < 1024 && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    galleryOpen && size.width <= 1024 ? "rotate-svg" : ""
                  }
                >
                  <path
                    d="M18 15L12 9L6 15"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <div
              className={`navigation__sublinks ${
                galleryOpen && size.width <= 1024
                  ? "navigation__sublinks_active"
                  : ""
              }`}
            >
              <Link
                to="/gallery"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Все проекты
              </Link>
              <Link
                to="/gallery/realized"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Реализованные проекты
              </Link>
              <Link
                to="/gallery/design"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Дизайн интерьеров квартир
              </Link>
            </div>
            <div
              className={`navigation__dropdown ${
                galleryOpen && size.width > 1024
                  ? "navigation__dropdown_active"
                  : ""
              }`}
            >
              <Link
                to="/gallery"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Все проекты
              </Link>
              <Link
                to="/gallery/realized"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Реализованные проекты
              </Link>
              <Link
                to="/gallery/design"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Дизайн интерьеров квартир
              </Link>
            </div>
          </div>
        </li>

        {/* Услуги */}
        <li
          className="navigation__element"
          onClick={toggleServices}
          onMouseEnter={() => size.width > 1024 && setServicesOpen(true)}
          onMouseLeave={() => size.width > 1024 && setServicesOpen(false)}
        >
          <div className="navigation__hover-area">
            <div className="navigation__el">
              <span className="navigation__link" style={{ cursor: "default" }}>
                УСЛУГИ
              </span>
              {size.width < 1024 && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={
                    servicesOpen && size.width <= 1024 ? "rotate-svg" : ""
                  }
                >
                  <path
                    d="M18 15L12 9L6 15"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <div
              className={`navigation__sublinks ${
                servicesOpen && size.width <= 1024
                  ? "navigation__sublinks_active"
                  : ""
              }`}
            >
              <Link
                to="/services/apartments"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Дизайн интерьера квартиры “Под ключ”
              </Link>
              <Link
                to="/services/houses"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Дизайн загородных домов “Под ключ”
              </Link>
              <Link
                to="/services/contracting"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Генеральный подряд
              </Link>
              <Link
                to="/services/supervision"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Авторский надзор
              </Link>
              <Link
                to="/services/renovation"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Ремонт квартир
              </Link>
            </div>
            <div
              className={`navigation__dropdown ${
                servicesOpen ? "navigation__dropdown_active" : ""
              }`}
            >
              <Link
                to="/services/apartments"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Дизайн интерьера квартиры “Под ключ”
              </Link>
              <Link
                to="/services/houses"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Дизайн загородных домов “Под ключ”
              </Link>
              <Link
                to="/services/contracting"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Генеральный подряд
              </Link>
              <Link
                to="/services/supervision"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Авторский надзор
              </Link>
              <Link
                to="/services/renovation"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Ремонт квартир
              </Link>
            </div>
          </div>
        </li>

        {/* Остальные элементы навигации */}
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/price" className="navigation__link">
              ЦЕНЫ
            </Link>
          </div>
        </li>
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/contacts" className="navigation__link">
              КОНТАКТЫ
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
